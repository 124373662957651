import { browser } from '$app/environment'
import { page } from '$app/stores'
import type { Date as CivilDate } from '$lib/gen/google/type/date_pb'
import { HotelSearchQuery } from '$lib/gen/hotel/v1/query_pb'
import { TravelSolution } from '$lib/gen/travelsolution/v1/travelsolution_pb'
import { decodeTravelSolution, defaultTravelSolution } from '$lib/solution/encoding'
import { gotoSolution, stageSolution, type GotoOptions } from '$lib/solution/router'
import type { PartialMessage } from '@bufbuild/protobuf'
import { derived, writable, type Readable } from 'svelte/store'

function getTsQueryNative() {
  if (browser) return new URLSearchParams(window.location.search).get('ts')
  return ''
}

function tsOrDefault() {
  if (browser) {
    const ts = getTsQueryNative()
    if (ts != null) {
      return decodeTravelSolution(ts)
    }
  }

  return defaultTravelSolution()
}

const ts = derived([page], ([$page]) =>
  import.meta.env.SSR ? null : $page.url.searchParams.get('ts'),
)
const store = writable<TravelSolution>(tsOrDefault())
const rateQueryInputsStore = writable<
  Pick<
    HotelSearchQuery,
    | 'checkIn'
    | 'checkOut'
    | 'adultCount'
    | 'childAges'
    | 'rateOption'
    | 'salesEnvironment'
    | 'salesChannel'
    | 'currencyCode'
  >
>(tsOrDefault().hotelQuery)

// rateQueryInputsStore.subscribe((query) => console.log('Hotel Query', query))

// function compareFields<T extends typeof Message>(
//   type: T,
//   a: PartialMessage<T>,
//   b: PartialMessage<T>,
//   fields: (keyof PlainMessage<T>)[],
// ) {
//   fields.forEach((fieldName) => {
//     const field = type.getType().fields.findJsonName(fieldName.toLocaleString())
//   })
// }

// const didInitializeSolution = writable<boolean>(false)
const lastSolutionString = writable<string>('')

export { ts }
export const rateQueryInputs = rateQueryInputsStore

const solution = derived(
  [ts, store, lastSolutionString, rateQueryInputsStore],
  ([$ts, $store, $lastSolutionString, $rateQueryInputs]) => {
    if ($ts == null || $ts.length < 4) {
      return $store // Store should be the default value
    }

    let current = $store || tsOrDefault()

    if ($ts !== $lastSolutionString) {
      lastSolutionString.set($ts)
      const solution = decodeTravelSolution($ts)
      current = solution
      store.set(current)

      // newQuery handles tracking changes only to the inputs needed for fetching rates.
      // The rateSvc subscribes to this store and will fetch rates when the inputs change.
      const newQuery = new HotelSearchQuery($rateQueryInputs)
      if (current.hotelQuery != null) {
        if (
          formatCivilDate(current.hotelQuery?.checkIn) !==
          formatCivilDate($rateQueryInputs?.checkIn)
        ) {
          newQuery.checkIn = current.hotelQuery?.checkIn
        }

        if (!current.hotelQuery?.checkOut?.equals($rateQueryInputs?.checkOut)) {
          newQuery.checkOut = current.hotelQuery.checkOut
        }

        if (current.hotelQuery?.adultCount != $rateQueryInputs?.adultCount) {
          newQuery.adultCount = current.hotelQuery.adultCount
        }

        if (
          current.hotelQuery?.childAges?.length != $rateQueryInputs?.childAges?.length ||
          current.hotelQuery?.childAges?.some((age, i) => age != $rateQueryInputs?.childAges?.[i])
        ) {
          newQuery.childAges = current.hotelQuery.childAges
        }

        if (current.hotelQuery?.currencyCode != $rateQueryInputs?.currencyCode) {
          newQuery.currencyCode = 'USD'
          // newQuery.currencyCode = current.hotelQuery.currencyCode
        }

        if (!newQuery.equals(new HotelSearchQuery($rateQueryInputs))) {
          rateQueryInputsStore.set(newQuery)
        }
      }
    }

    return current
  },
)

const solutionStore: Readable<TravelSolution> & {
  update(
    this: void,
    value: PartialMessage<TravelSolution>,
    gotoOptions?: GotoOptions,
  ): Promise<void>
} = {
  subscribe: solution.subscribe,
  update: async (value: PartialMessage<TravelSolution>, gotoOptions?: GotoOptions) => {
    const [nextSolution, didChange] = await new Promise<[TravelSolution, boolean]>((done) => {
      store.update((solution) => {
        if (solution.equals(new TravelSolution(value))) {
          done([solution, false])
          return solution
        }

        const nextSolution = stageSolution(solution, value)
        requestAnimationFrame(() => done([nextSolution, true]))
        return nextSolution
      })
    })
    if (didChange && browser) {
      const url = new URL(window.location.href)
      await gotoSolution(nextSolution, url, gotoOptions)
    }
  },
}

function logQueryDates(sol: PartialMessage<TravelSolution>) {
  return [formatCivilDate(sol?.hotelQuery?.checkIn), formatCivilDate(sol?.hotelQuery?.checkOut)]
}

function formatCivilDate(date: PartialMessage<CivilDate>) {
  return [
    date?.year.toFixed(0).padStart(4, '0'),
    date?.month.toFixed(0).padStart(2, '0'),
    date?.day.toFixed(0).padStart(2, '0'),
  ].join('-')
}

function getCallStack() {
  const obj = {} as Error
  Error.captureStackTrace(obj, getCallStack)
  return obj.stack
}

export default solutionStore
