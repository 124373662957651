<script>
  import { Loader } from '@googlemaps/js-api-loader'
  import { createEventDispatcher, onMount } from 'svelte'

  const dispatch = createEventDispatcher()

  export let apiKey

  onMount(() => {
    new Loader({
      apiKey: apiKey,
      version: 'weekly',
      libraries: ['places'],
    })
      .load()
      .then((sdk) => dispatch('ready', sdk))
  })
</script>
