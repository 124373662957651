import { writable, type Readable } from 'svelte/store'

type LocationStore = {
  locationId: string
}

const store = writable<LocationStore>({ locationId: 'US' })

function setLocation(locationId: string) {
  store.update((state) => ({ ...state, locationId }))
}

const originLocation: Readable<LocationStore> & { setLocation: typeof setLocation } = {
  subscribe: store.subscribe,
  setLocation,
}

export default originLocation
