<script lang="ts" context="module">
  export type EventTypes = {
    change: { adults: number; children: number[] }
    clear: void
  }
</script>

<script lang="ts">
  import { debounce } from 'lodash-es'
  import type { MaxOccupancy } from 'src/utils/stays/occupancy'
  import { createEventDispatcher, tick } from 'svelte'
  import Title from '../typography/Title.svelte'

  export let adults: number
  export let children: number[]
  export let maxOccupancy: MaxOccupancy = null

  $: guestCount = adults + children?.length ?? 0
  $: hasChildren = children?.length > 0
  $: maxChildAge = maxOccupancy != null ? maxOccupancy?.minAdultAge - 1 : 17
  $: minChildAge = maxOccupancy != null ? maxOccupancy?.minChildAge : 0

  const dispatch = createEventDispatcher<EventTypes>()
  const debouncedDispatch = debounce(dispatch, 500, { leading: true })

  function addAdult() {
    if (guestCount > 15) return
    adults += 1

    tick().then(() => debouncedDispatch('change', { adults: adults, children: children }))
  }

  function removeAdult() {
    if ((children.length > 0 && adults == 1) || adults < 1) return
    adults -= 1
    tick().then(() => debouncedDispatch('change', { adults: adults, children: children }))
  }

  function removeChild() {
    if (children.length < 1) return
    children.pop()
    tick().then(() => debouncedDispatch('change', { adults: adults, children: children }))
  }

  function addChild() {
    if (guestCount > 15) return
    children.push(minChildAge)
    if (adults == 0) {
      adults = 1
    }
    tick().then(() => debouncedDispatch('change', { adults: adults, children: children }))
  }

  function deleteChild(idx: number) {
    children.splice(idx, 1)
    tick().then(() => debouncedDispatch('change', { adults: adults, children: children }))
  }

  function checkChildAge() {
    let newChildren = children.map((c) => {
      return c > maxChildAge ? maxChildAge : c
    })
    children = newChildren
    tick().then(() => debouncedDispatch('change', { adults: adults, children: children }))
  }
</script>

<div class="flex items-center justify-between w-full px-4 py-2 pt-6 mt-4">
  <div class="flex flex-col">
    <h4 class="text-sm font-semibold text-gray-900">Adults</h4>
    <span class="flex flex-col text-xs text-gray-500">
      <span>{maxOccupancy != null ? maxOccupancy?.minAdultAge : 18} years old +</span>
      {#if maxOccupancy != null}
        <span>max {maxOccupancy?.maxAdults} per room</span>
      {/if}
    </span>
  </div>

  <div class="flex items-center justify-end gap-x-4">
    <button
      class="flex items-center justify-center p-4 rounded-full disabled:bg-white disabled:cursor-not-allowed bg-grey-main hover:bg-grey-md"
      on:click={removeAdult}
      title={'remove adult'}
      disabled={adults <= 1 || (children.length > 0 && adults == 1)}
    >
      <img
        src={'/marketing/icons/remove.svg'}
        class="h-[12px]"
        width={12}
        height={12}
        loading="lazy"
        alt={`decrement icon`}
      />
    </button>
    <span class="flex items-center justify-center w-4">{adults}</span>
    <button
      class="flex items-center justify-center p-4 rounded-full disabled:bg-white disabled:cursor-not-allowed bg-grey-main hover:bg-grey-md"
      on:click={addAdult}
      title={'add adult'}
      disabled={guestCount > 15 || (maxOccupancy != null && adults >= maxOccupancy.maxAdults)}
    >
      <img
        src={'/marketing/icons/add.svg'}
        class="h-[12px]"
        width={12}
        height={12}
        loading="lazy"
        alt={`increment icon`}
      />
    </button>
  </div>
</div>
<div class="flex flex-col px-4 py-2 pb-6 gap-y-4">
  <div class="flex items-center justify-between w-full mt-2">
    <div class="flex flex-col">
      <h4 class="text-sm font-semibold">Children</h4>
      <span class="flex flex-col text-xs text-gray-500">
        {#if minChildAge == maxChildAge}
          <span>{maxChildAge} years old</span>
        {:else}
          <span>{minChildAge} to {maxChildAge} years old</span>
        {/if}
        {#if maxOccupancy != null}
          <span>max {maxOccupancy?.maxChildren} per room</span>
        {/if}
      </span>
    </div>

    <div class="flex items-center justify-end gap-x-4">
      <button
        class="flex items-center justify-center p-4 rounded-full disabled:bg-white disabled:cursor-not-allowed bg-grey-main hover:bg-grey-md"
        on:click={removeChild}
        title={'remove child'}
        disabled={children.length < 1}
      >
        <img
          src={'/marketing/icons/remove.svg'}
          class="h-[12px]"
          width={12}
          height={12}
          loading="lazy"
          alt={`decrement icon`}
        />
      </button>
      <span class="flex items-center justify-center w-4">{children.length}</span>
      <button
        class="flex items-center justify-center p-4 rounded-full disabled:bg-white disabled:cursor-not-allowed bg-grey-main hover:bg-grey-md"
        on:click={addChild}
        title={'add child'}
        disabled={guestCount > 15 ||
          (maxOccupancy != null && children.length >= maxOccupancy.maxChildren)}
      >
        <img
          src={'/marketing/icons/add.svg'}
          class="h-[12px]"
          width={12}
          height={12}
          loading="lazy"
          alt={`increment icon`}
        />
      </button>
    </div>
  </div>
  {#if hasChildren}
    <div class="flex flex-col overflow-y-auto gap-y-1">
      <Title>Ages</Title>
      {#each children as child, index (index)}
        <div class="flex items-center w-full gap-x-4">
          <input
            type="number"
            placeholder="Enter child's age"
            bind:value={child}
            min={minChildAge}
            max={maxChildAge}
            on:change={checkChildAge}
            class="flex items-center justify-between flex-1 rounded-md ring-0 focus:ring-0 focus:border-black border-grey-dk"
          />
          <button
            tabindex="-1"
            class="flex items-center justify-center flex-shrink-0 px-[17px] py-4 rounded-full bg-grey-main hover:bg-grey-md"
            on:click={() => deleteChild(index)}
            title={'delete child'}
          >
            <img
              src={'/marketing/icons/delete.svg'}
              class="h-[12px]"
              width={10}
              height={12}
              loading="lazy"
              alt={`delete icon`}
            />
          </button>
        </div>
      {/each}
    </div>
  {/if}
</div>
