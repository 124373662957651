<script lang="ts">
  import type { Day } from '$lib/gen/rates/v1/calendar_pb'
  import { fromPBDate } from '$lib/solution/date'
  import { compactCurrencies, toAmount } from '$lib/utils/currency'
  import { differenceInMonths, isSameDay } from 'date-fns'
  import { isBetween, listCalDates, writeDateString } from 'src/utils/calendar'
  import { createEventDispatcher, onMount, tick } from 'svelte'
  import Amount from '../ui/Amount.svelte'

  export let checkIn: Date
  export let checkOut: Date
  export let isMobile = false
  export let priceCalendar: Day[] = []
  export let firstQuantile: number = null
  export let lastQuantile: number = null
  export let currency: string = 'USD'
  export let isLoadingRates: boolean = false

  $: currentSelect = checkIn == null ? 'start' : checkOut == null ? 'end' : 'both'

  const MAX_TRIP_DURATION = 28

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
  }

  const indexInc = isMobile ? 14 : 2
  const now = new Date()
  let monthIndex = isMobile ? 0 : differenceInMonths(checkIn, now)

  const allMonths = listCalDates(now)

  function handleNavForward() {
    monthIndex += 1
  }
  function handleNavBackwards() {
    monthIndex -= 1
  }

  const dispatch = createEventDispatcher<{
    change: { checkIn: Date; checkOut: Date }
    mount: void
  }>()

  async function handleUpdateSelectDate(selectedDate: Date) {
    if (currentSelect === 'start') {
      checkIn = selectedDate
      checkOut = null
    } else if (currentSelect === 'end') {
      checkOut = selectedDate
    } else {
      checkIn = selectedDate
      checkOut = null
    }

    if (checkIn != null && checkOut != null) {
      tick().then(() => dispatch('change', { checkIn, checkOut }))
    }
  }

  onMount(() => {
    dispatch('mount')
  })
</script>

<div class="relative grid gap-y-6 sm:gap-x-8 grid-cols-2">
  <div
    class={`items-center justify-between col-span-2 md:-mb-16 z-[11] ${
      isMobile ? 'hidden' : 'flex'
    }`}
  >
    <button
      class="flex items-center p-4 w-10 h-10 justify-center disabled:bg-white disabled:cursor-not-allowed bg-grey-main hover:bg-grey-md rounded-full"
      on:click={handleNavBackwards}
      title={'previous month'}
      disabled={monthIndex === 0}
    >
      <img
        src={'/marketing/icons/left-arrow.svg'}
        class="h-[12px]"
        width={7}
        height={12}
        loading="lazy"
        alt={`left arrow`}
      />
    </button>
    <button
      class="flex items-center p-4 w-10 h-10 justify-center disabled:bg-white disabled:cursor-not-allowed bg-grey-main hover:bg-grey-md rounded-full"
      on:click={handleNavForward}
      title={'next month'}
      disabled={monthIndex >= 11}
    >
      <img
        src={'/marketing/icons/right-arrow.svg'}
        class="h-[12px]"
        width={7}
        height={12}
        loading="lazy"
        alt={`right arrow`}
      />
    </button>
  </div>
  {#each allMonths.slice(monthIndex, monthIndex + indexInc) as month, monthIdx}
    <section class="col-span-2 sm:col-span-1 text-left sm:text-center">
      <h2 class="font-semibold text-sm text-gray-900">
        {month.name}
        {month.year}
      </h2>
      <div class="sm:grid grid-cols-7 mt-4 text-xs leading-6 text-gray-500 hidden">
        <div>S</div>
        <div>M</div>
        <div>T</div>
        <div>W</div>
        <div>T</div>
        <div>F</div>
        <div>S</div>
      </div>
      <div
        class="grid grid-cols-7 gap-px mt-2 text-sm bg-gray-200 rounded-md shadow isolate ring-1 ring-gray-200"
      >
        {#each month.days as day, dayIdx}
          {@const isAllowed =
            currentSelect != 'end'
              ? true
              : isBetween(
                  checkIn,
                  day.date,
                  new Date(
                    checkIn?.getFullYear(),
                    checkIn?.getMonth(),
                    checkIn?.getDate() + MAX_TRIP_DURATION + 1,
                  ),
                )}
          {@const price = priceCalendar.find((p) => isSameDay(fromPBDate(p.day), day.date))}
          {@const isDisabled = !day.isCurrentMonth || day.isPast || !isAllowed}
          {@const isSelected =
            day.isCurrentMonth &&
            (writeDateString(day.date) === writeDateString(checkIn) ||
              writeDateString(day.date) === writeDateString(checkOut))}
          {@const isNotSelectable =
            !day.isCurrentMonth || day.isPast || !isAllowed || price?.minInclusivePrice == null}
          {@const isWithinRange = isBetween(checkIn, day.date, checkOut)}
          {@const isGreen = price?.minInclusivePrice / 100 < firstQuantile * 1.05}
          {@const isRed = price?.minInclusivePrice / 100 > lastQuantile}

          <button
            type="button"
            value={day.date}
            on:click={() => handleUpdateSelectDate(day.date)}
            disabled={isDisabled}
            class={classNames(
              isSelected
                ? 'bg-black text-white hover:opacity-80'
                : isDisabled
                ? 'bg-gray-50 text-gray-400'
                : isWithinRange
                ? 'bg-grey-main text-black hover:bg-grey-md'
                : 'bg-white text-gray-900 hover:bg-grey-md',
              dayIdx === 0 && 'rounded-tl-lg',
              dayIdx === 6 && 'rounded-tr-lg',
              dayIdx === month.days.length - 7 && 'rounded-bl-lg',
              dayIdx === month.days.length - 1 && 'rounded-br-lg',
              'relative py-1.5 focus:z-10 disabled:cursor-not-allowed',
            )}
          >
            <time
              dateTime={day.date.toString()}
              class={classNames(
                'mx-auto flex flex-col h-10 w-10 items-center justify-center rounded-full',
              )}
            >
              <div class="text-base font-medium">
                {day.isCurrentMonth ? day.date.getDate() : ' '}
              </div>
              <div class="h-4 leading-3 flex items-center justify-center">
                {#if isLoadingRates && !(!day.isCurrentMonth || day.isPast)}
                  <div class="animate-pulse h-2 w-8 bg-grey-md rounded" />
                {:else if price?.minInclusivePrice && !isNotSelectable}
                  <Amount
                    loading={isLoadingRates}
                    class={`text-xs ${
                      isSelected
                        ? 'text-white'
                        : isGreen
                        ? 'text-green-700'
                        : isRed
                        ? 'text-red-700'
                        : 'text-gray-700'
                    }`}
                    amount={toAmount(price?.minInclusivePrice, currency)}
                    options={{
                      currencyDisplay: 'narrowSymbol',
                      notation: 'compact',
                      maximumFractionDigits: 1,
                    }}
                    rounded
                  />
                {/if}
              </div>
            </time>
          </button>
        {/each}
      </div>
    </section>
  {/each}
</div>
