import type { HotelCardRoomFieldsFragment } from '$lib/queries/fragments/generated/HotelCardRoomFields'
import type { RoomFieldsFragment } from '$lib/queries/fragments/generated/RoomFields'

export type RoomLayout = { roomId: string; bedrooms: number; bathrooms: number }

export function getLayout(
  rooms: RoomFieldsFragment[] | HotelCardRoomFieldsFragment[],
): RoomLayout[] {
  const bedBath = []
  rooms.forEach((room) => {
    let numBedrooms = 0
    const title = room.name?.match(/(\d+) Bedroom/)
    if (title != null) {
      const bedrooms = title[0].match(/(\d+)/)
      numBedrooms = parseInt(bedrooms[0])
    } else if (room.name?.match(/One Bedroom/)) {
      numBedrooms = 1
    } else if (room.name?.match(/Two Bedroom/)) {
      numBedrooms = 2
    } else if (room.name?.match(/Three Bedroom/)) {
      numBedrooms = 3
    } else if (room.name?.match(/Studio/)) {
      numBedrooms = 0
    } else {
      const layout = room.description.match(/Layout<\/b>.*?<\/p>/)
      if (layout != null) {
        const layoutText = layout[0].replace(/<\/?[^>]+(>|$)/g, '')
        const multiBedroom = layoutText.match(/(\d+) bedrooms/)
        if (multiBedroom != null) {
          const bedrooms = multiBedroom[0].match(/(\d+)/)
          numBedrooms = parseInt(bedrooms[0])
        } else if (layoutText.match(/Bedroom/)) {
          numBedrooms = 1
        }
      }
    }
    let numBathrooms = null
    const bathroom = room.description.match(/Bathroom<\/b>.*?<\/p>/)
    if (bathroom != null) {
      const bathroomText = bathroom[0].replace(/<\/?[^>]+(>|$)/g, '')
      const multiBathroom = bathroomText.match(/(\d+) bathrooms/)
      if (multiBathroom != null) {
        const bathrooms = multiBathroom[0].match(/(\d+)/)
        numBathrooms = parseInt(bathrooms[0])
      } else if (bathroomText.match(/Shared bathroom/)) {
        numBathrooms = 0
      } else if (bathroomText.match(/Private bathroom/)) {
        numBathrooms = 1
      }
    }
    bedBath.push({
      roomId: room.id,
      bedrooms: numBedrooms,
      bathrooms: numBathrooms,
    })
  })
  bedBath.sort((a, b) => a.bedrooms - b.bedrooms)
  return bedBath
}

export function getNumBedroomDescription(
  layout: { bedrooms: number; bathrooms: number }[],
): string {
  const minBedrooms = Math.min(...layout.map((l) => l.bedrooms ?? 0))
  const maxBedrooms = Math.max(...layout.map((l) => l.bedrooms ?? 0))
  return minBedrooms == maxBedrooms
    ? minBedrooms == 0
      ? 'Studio units only'
      : `${minBedrooms} bedroom units only`
    : `${[...new Set(layout.map((l) => (l.bedrooms == 0 ? 'Studio' : l.bedrooms)))].join(
        ', ',
      )} bedroom units`
}
