import Cookies from 'js-cookie'
import originLocation from '$lib/stores/originLocation'

export interface UserLocationResponse {
  lat: string
  lng: string
  colo: string
  continent: string
  country: string
  region: string
  regionCode: string
  city: string
  metroCode: string
  postalCode: string
  asn: number
  asOrganization: string
  timezone: string
  tlsVersion: string
  placeId: string
  airport: Airport
}

export interface Airport {
  name: string
  iata: string
  icao: string
  lat: number
  lng: number
  placeId: string
}

const obfuscatePrefixLength = ")]}'\n".length

export default async function getUserLocation(): Promise<UserLocationResponse> {
  return fetch('https://ext.airheart.com/loc')
    .then((res) => res.text())
    .then((text) => text.slice(obfuscatePrefixLength))
    .then<UserLocationResponse>(JSON.parse)
}

export async function getUserLocationCookie() {
  const originLocationId = Cookies.get('origin-location')
  if (
    originLocationId != null &&
    typeof originLocationId == 'string' &&
    originLocationId.length == 2
  )
    originLocation.setLocation(originLocationId)
  else {
    return getUserLocation().then((location) => {
      setUserLocationCookie(location?.country)
    })
  }
}

export function setUserLocationCookie(originLocationId: string) {
  Cookies.set('origin-location', originLocationId, { path: '/' })
  originLocation.setLocation(originLocationId)
}
