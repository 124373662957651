<script lang="ts">
  import { filterBadges } from '$lib/photos/highlights'

  import type { PhotoFieldsFragment } from '$lib/queries/fragments/generated/PhotoFields'

  import cdnImageURL, { cdnSrcSetHiDPI } from 'src/utils/cdn'
  import { swipe } from 'svelte-gestures'
  import { fade } from 'svelte/transition'

  export let photos: PhotoFieldsFragment[]
  export let index = 0
  export let isMarker = false
  export let isModal = false

  $: collection = filterBadges(photos)
    .slice(0, 8)
    .filter((photo) => photo?.url != null)
  $: totalPhotos = collection.length ?? 1

  let direction = null

  const imageSrcCache = new Set<string>()

  function preload(photos: PhotoFieldsFragment[]) {
    photos?.forEach((photo) => {
      if (imageSrcCache.has(photo?.id)) return
      imageSrcCache.add(photo?.id)
      let img = new Image()
      img.onerror = (err) => {
        console.warn('Preload err', err)
      }
      // img.onload = () => imageSrcCache.add(photo.id)
      img.src = cdnImageURL({ src: photo?.url, width: 275, height: 250 })
      img.srcset = cdnSrcSetHiDPI(photo?.url, 275, 250)
    })
  }

  function handler(event) {
    direction = event.detail.direction
    if (direction === 'left') {
      forward(event)
    } else if (direction === 'right') {
      back(event)
    }
  }

  const forward = (event?: TouchEvent | MouseEvent) => {
    // NOTE: Can't use preventDefault on touch events because we want them to be passive.
    if (event.type == 'click') event?.preventDefault()
    preload(collection)
    index = (index + 1) % totalPhotos
  }
  const back = (event?: TouchEvent | MouseEvent) => {
    if (event.type == 'click') event?.preventDefault()

    // loadPreviousPage()
    // if (fetchingNextPage) return

    preload(collection.slice(index, index - 10))

    if (index === 0) {
      index = totalPhotos - 1
    } else {
      index = (index - 1) % totalPhotos
    }
  }

  // const debouncedForward = debounce(forward, 200, { leading: true })
  // const debouncedBack = debounce(back, 200, { leading: true })
</script>

<div class="relative group flex-shrink-0 aspect-x-5 aspect-y-5">
  {#if collection.length > 1}
    <div
      use:swipe={{ timeframe: 300, minSwipeDistance: 100, touchAction: 'pan-y' }}
      on:swipe={handler}
    >
      {#each [collection[index]] as photo, i (index)}
        <img
          on:load|once
          in:fade|local
          src={cdnImageURL({ src: photo?.url, width: 275, height: 275, quality: 70 })}
          srcset={cdnSrcSetHiDPI(photo?.url ?? '', 275, 275)}
          alt={photo?.caption ?? 'photo'}
          width={275}
          height={275}
          loading={index == 0 ? 'eager' : 'lazy'}
          class={`object-cover aspect-x-5 aspect-y-5 ${
            isMarker
              ? 'rounded-l-lg sm:rounded-b-none sm:rounded-t-2xl w-[120px] h-[120px] sm:h-[275px] sm:w-[275px]'
              : 'w-full h-[275px] rounded-2xl'
          }`}
        />
      {/each}
    </div>
    <button
      type="button"
      on:click|stopPropagation={back}
      on:dblclick|stopPropagation={back}
      on:touchstart|stopPropagation|passive={back}
      class="absolute w-12 z-10 hidden group-hover:flex items-center justify-center top-0 bottom-0 left-0 h-full"
    >
      <div
        class="flex z-10 items-center shadow-lg justify-center w-8 h-8 bg-white rounded-full opacity-100"
      >
        <img
          src={`/marketing/icons/left-arrow-carousel.svg`}
          width={6}
          height={12}
          class="-ml-[2px]"
          alt="left arrow"
          aria-label="left arrow"
        />
      </div>
    </button>
    <button
      type="button"
      on:click|stopPropagation={forward}
      on:dblclick|stopPropagation={forward}
      on:touchstart|stopPropagation|passive={forward}
      class="absolute w-12 z-10 hidden group-hover:flex items-center justify-center top-0 bottom-0 right-0 h-full"
    >
      <div
        class="flex z-10 items-center shadow-lg justify-center w-8 h-8 bg-white rounded-full opacity-100"
      >
        <img
          src={`/marketing/icons/right-arrow-carousel.svg`}
          width={6}
          height={12}
          class="-mr-[2px]"
          alt="right arrow"
          aria-label="right arrow"
        />
      </div>
    </button>
    <div
      class={`absolute lg:hidden group-hover:flex bottom-4 z-10 flex items-center justify-center w-full ${
        isMarker ? 'gap-x-1 sm:gap-x-2' : 'gap-x-2'
      }`}
    >
      {#each collection as photo, i (photo.id)}
        <span
          class={`rounded-full transition-all bg-white ${
            index === i ? 'opactiy-100 scale-150' : 'opactiy-30'
          }
          ${isMarker ? 'w-1 h-1 sm:w-1.5 sm:h-1.5 ' : 'w-1.5 h-1.5 '}`}
        />
      {/each}
    </div>
  {:else}
    <div class="inline-flex overflow-hidden w-full h-full">
      {#each collection.slice(0, 1) as photo, i (index)}
        <img
          on:load|once
          in:fade|local
          src={cdnImageURL({ src: photo.url, width: 275, height: 275, quality: 70 })}
          srcset={cdnSrcSetHiDPI(photo.url ?? '', 275, 275)}
          alt={photo?.caption ?? 'photo'}
          width={275}
          height={275}
          loading={index == 0 ? 'eager' : 'lazy'}
          class={`object-cover w-full h-[275px] aspect-x-5 aspect-y-5 ${
            isMarker ? 'rounded-t-2xl' : 'rounded-2xl'
          }`}
        />
      {/each}
    </div>
  {/if}
</div>

<style global lang="postcss">
  .carousel {
    display: inline-flex;
    overflow-x: hidden;
    /*  scroll snap is a great feature which will center the image on snap on touch screen devices  */
    scroll-snap-type: x mandatory;
    /* all below will hide the scrollbar on all browsers.    */
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
  }
</style>
