import solution from '$lib/stores/solution'
import Cookies from 'js-cookie'

export async function setCurrencyCookie(currencyId: string) {
  currencyId = 'USD'
  Cookies.set('currency', currencyId, { path: '/' })
  if (currencyId != null && typeof currencyId == 'string' && currencyId.length == 3) {
    await solution.update({
      hotelQuery: {
        currencyCode: currencyId,
      },
    })
  }
}

export function getCurrencyCookie() {
  // const currency = Cookies.get('currency') ?? 'USD'
  // return currency
  return 'USD'
}
