import type { Rate } from '$lib/gen/rates/v1/rate_pb'
import type { HotelCardFieldsFragment } from '$lib/queries/fragments/generated/HotelCardFields'
import { compareAsc, sub } from 'date-fns'

export function isAGem(starRating: number, guestRating: number) {
  if (starRating > 0 && guestRating > 0) {
    switch (starRating) {
      case 5:
        return starRating - guestRating < 0.2 ? true : false
      case 4.5:
        return guestRating - starRating > 0.3 ? true : false
      case 4:
        return guestRating - starRating > 0.5 ? true : false
      case 3.5:
        return guestRating - starRating > 1 ? true : false
      case 3:
        return guestRating - starRating > 1.2 ? true : false
      default:
        return guestRating - starRating > 2 ? true : false
    }
  }
}

export function getDiscount(
  hotel: Pick<HotelCardFieldsFragment, 'minInclusivePrice' | 'strikeThroughPrice'>,
) {
  if (hotel.strikeThroughPrice == null) return 0

  return Math.round(
    ((hotel.strikeThroughPrice - hotel.minInclusivePrice) / hotel.strikeThroughPrice) * 100,
  )
}

export function getStrikeThrough(bestRate: Rate) {
  return bestRate.strikethrough
  // const rateTotals =
  //   bestRate?.occupancyPricing.
  //     .filter((r) => r.value?.totals?.inclusive == bestRate.inclusivePrice)
  //     .map((p) => p.value?.totals) ?? null
  // if (rateTotals == null) return false
  // return Math.min(...rateTotals.map((p) => p.strikethrough))
}

export function isNew(createdAt: string) {
  if (createdAt == null) return false

  const createdAtDate = new Date(createdAt)
  const cutoff = sub(new Date(), { days: 90 })
  return compareAsc(createdAtDate, cutoff) === 1 ? true : false
}
